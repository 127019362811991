<template>
  <div class="page-header-title">
    <h3 class="m-b-10">{{ $t('sandboxRequests') }}</h3>
  </div>
  <paginated-table-wrapper
    v-if="license"
    :sort="'desc'"
    :license="license"
    :request="getSandboxMessagesRequest"
    v-slot="{ list, isLoading }"
  >
    <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
    <paginated-table
      :list="list"
    ></paginated-table>
  </paginated-table-wrapper>
</template>

<script>
import { computed, defineAsyncComponent } from 'vue'
import { getSandboxMessages } from '@/application/services/statistic.js'
import { store } from '@/application/store'

export default {
  name: 'ScheduledCampaignsPage',
  components: {
    'paginated-table-wrapper': defineAsyncComponent(() => import('@/application/components/elements/PaginatedTable/Wrapper.vue')),
    'paginated-table': defineAsyncComponent(() => import('@/application/components/elements/message-tables/SandboxMessagesTable.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    const getSandboxMessagesRequest = getSandboxMessages
    return {
      license,
      getSandboxMessagesRequest,
    }
  },
}
</script>
